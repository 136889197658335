@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
}

@media (prefers-color-scheme: dark) {
  :root {
  }
} */

@layer components {
  .btn-primary {
    @apply bg-black hover:bg-slate-700 text-white font-bold py-2 px-4 rounded;
  }

  .link-primary {
    color: rgb(0, 111, 238);
    cursor: pointer;
  }

  .icon-primary {
    color: rgb(0, 111, 238);
  }

  .controller-wrapper {
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;

  }

  .controller-wrapper:hover {
    background-color: rgb(229, 231, 235);
  }
  .controller-wrapper.error {
    background-color: rgb(254, 231, 239);
  }

  .controller-wrapper label {
    font-size: 12px;
    color: rgb(82, 82, 91);
  }

  .controller-wrapper .PhoneInput input {
    color: rgb(17, 24, 28) !important;
    background-color: inherit !important;
    border: none;
    font-size: 0.875rem;
  }

  .controller-wrapper .calendar input {
    color: rgb(17, 24, 28) !important;
    background-color: inherit !important;
    font-size: 0.875rem;
    box-shadow: none !important;
  }

  .controller-wrapper.error .calendar input,
  .controller-wrapper.error svg,
  .controller-wrapper.error label {
    color: rgb(243, 18, 96) !important;
  }


  .controller-error {
    color: rgb(243, 18, 96);
    padding-left: 7px;
    font-size: 12px;
  }

  .controller-wrapper input {
    font-size: 0.875rem;
    background-color: inherit !important;
  }

  .controller-wrapper .flex input,
  .controller-wrapper input.pac-target-input {
    width: 100%;
    border: none;
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .form-error {
    color: rgb(243, 18, 96);
    font-size: 12px;
    padding-top: 12px;
    padding-left: 10px;
  }

  .form-error.inline {
    padding-top: 5px;
  }

  .drop-container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
  }
  
  .drop-container > p {
    font-size: 1rem;
  }
  
  .drop-container > em {
    font-size: .8rem;
  }
  
  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 0.5rem;
    font-size: 0.9em;
    border-color: #ccc;
    border-style: dashed;
    background-color: rgb(244, 244, 245);
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }

  .dropzone.error {
    background-color: rgb(254, 231, 239);
    border-color: rgb(255, 252, 250);
  }

  .dropzone:focus {
    border-color: #2196f3;
  }
  
  .dropzone.disabled {
    opacity: 0.6;
  }
  
}